<template>
  <el-card id="changeLog">
    <header>
      <h2 style="display: flex; align-items: center">
        坐席日志信息<span v-if="showTitleText"
          >(可以查看所有充值信息、每个坐席扣费信息、短信扣费信息、坐席删除信息以及回收号码个数信息)</span
        >
        <el-tooltip
          class="item"
          effect="dark"
          content="点击查看详情说明"
          placement="top"
        >
          <img
            v-if="!showTitleText"
            src="@/assets/images/what.png"
            alt=""
            style="width: 30px; height: 30px; cursor: pointer"
            @click="showTitleText = true"
          />
        </el-tooltip>
      </h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input
              v-model="form.id"
              placeholder="请输入公司ID"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input
              v-model="form.realname"
              placeholder="请输入公司名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>备注</span>
            <el-input
              v-model="form.remarks"
              placeholder="请输入备注"
              size="mini"
            ></el-input>
          </div>
          <!-- <div class="left-wrap">
            <span></span>
            <el-radio v-model="typeCode" label="" style="margin: 0;" border size="mini">全部企业</el-radio>
            <el-radio v-model="typeCode" :label="1" border size="mini">套餐超出企业</el-radio>
          </div> -->
          <div class="left-wrap" style="display: block">
            <span>时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              :clearable="false"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr />
    <main>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="用户编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="用户名称"
          align="center"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="充值后余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="oldMoney"
          label="充值前余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注信息"
          align="center"
          width="200"
        ></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import axios from "axios";
import { logUserList, logUserList1 } from "@/api/log";
export default {
  data() {
    return {
      timer: null,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          const day = 30 * 24 * 3600 * 1000; // 90天
          if (this.pickerOneDate) {
            let maxTime = this.pickerOneDate + day;
            let minTime = this.pickerOneDate - day;
            return (
              time.getTime() > maxTime ||
              time.getTime() < minTime ||
              time.getTime() > Date.now()
            );
          } else {
            return (
              time.getTime() > Date.now() ||
              time.getTime() < Date.now() - day * 3
            );
          }
        },
      },
      showTitleText: false,
      role: null,
      form: {
        id: null,
        name: "",
        oldMoney: "",
        money: "",
        createTime: "",
        remarks: null,
      },
      typeCode: "",
      createTime: "",
      adminSum_Z: "admin充值",
      adminSum_F: "admin扣款",
      roleName: null,
      realname: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      listType: 1,
    };
  },
  created() {
    this.kefuParentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.realname = localStorage.getItem("realname");
    this.role = localStorage.getItem("role");

    this.sta = this.formatDate(new Date()).substring(0, 10) + " 00:00:00";
    this.end = this.formatDate(new Date()).substring(0, 10) + " 23:59:59";
    this.createTime = [
      this.formatDate(new Date()).substring(0, 10) + " 00:00:00",
      this.formatDate(new Date()).substring(0, 10) + " 23:59:59",
    ];

    this.getList();
  },
  activated() {
    // this.getList();
  },
  methods: {
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();

      // if (2 === this.listType) {
      //   this.getList2();
      // } else if (3 === this.listType) {
      //   this.getList3();
      // } else {
      //   this.getList();
      // }
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
      // if (2 === this.listType) {
      //   this.getList2();
      // } else if (3 === this.listType) {
      //   this.getList3();
      // } else {
      //   this.getList();
      // }
    },
    // 展示
    getList() {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        if (this.createTime) {
          this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
          this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
        } else {
          if (this.roleName != "管理员") {
            Message.warning("请选择日期范围!");
            this.timer = null;
            return false;
          } else {
            this.sta = null;
            this.end = null;
          }
        }
        this.listType = 1;
        let params = {
          id: this.form.id,
          page: this.page,
          size: this.size,
          remarks: this.form.remarks,
          realname: this.form.realname,
          sta: this.sta,
          end: this.end,
        };

        // if (this.typeCode) params.typeCode = this.typeCode

        if (this.kefuParentId == 3555 || this.role == "客服") {
          let res = logUserList1(params);
          res.then((res) => {
            if (res.data.success == false) {
              this.$router.push("/403");
            } else {
              this.tableData = res.data.data;
              this.pageTotal = res.data.total;
            }
          });
        } else {
          let res = logUserList(params);
          res.then((res) => {
            if (res.data.success == false) {
              this.$router.push("/403");
            } else {
              this.tableData = res.data.data;
              this.pageTotal = res.data.total;
            }
          });
        }
        this.timer = null;
      }, 500);
    },

    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 重置
    reset() {
      this.form.realname = null;
      this.form.remarks = null;
      this.form.id = null;
      if (this.roleName == "管理员") {
        this.createTime = null;
        this.sta = null;
        this.end = null;
      }
      // this.typeCode = ''
      this.adminSum_Z = "admin充值";
      this.adminSum_F = "admin扣款";
      this.getList();
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      if (this.form.id == "") {
        this.form.id = null;
      }
      // if (this.createTime) {
      //   this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
      //   this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
      // }
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278fe;
  border-color: #3278fe;

  &:hover {
    opacity: 0.7;
  }
}

#changeLog {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>